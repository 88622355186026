import {
  Box,
  Link,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FaLocationArrow, FaSearch } from 'react-icons/fa'
import {
  useJsApiLoader,
  Autocomplete,
//  Polyline,
} from '@react-google-maps/api'
import { MultiRouteMap } from "./MultiRouteMap";

import { useRef, useState, useEffect, useCallback } from 'react'
//import { decode } from "@googlemaps/polyline-codec";
import {getRoute, getRoutes} from './RoutesAPI'
import {getAssetsForRoute} from './AssetsAPI'
import { isMobile } from 'react-device-detect';

const coreProps = {
  apiUrl: process.env.REACT_APP_BASE_URL, 
  organizationId: process.env.REACT_APP_ORGANIZATION_ID, 
  authToken: process.env.REACT_APP_AUTH_TOKEN};

const defaultGuy = process.env.REACT_APP_DEFAULT_ASSET_ID;
const defaultRoute = process.env.REACT_APP_DEFAULT_ROUTE_ID;

const libraries = ['places', 'drawing', 'marker', 'core'];

function App() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  })

  const kMetersToMiles = 0.621371 / 1000.0;

  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const [offRoute, setOffRoute] = useState('')
  const [heading, setHeading] = useState('')
  const [altitude, setAltitude] = useState('')
  const [title, setTitle] = useState('')
//  const [name, setName] = useState('')
  const [speed, setSpeed] = useState('')
  const [currentLocation, setCurrentLocation] = useState({ lat: 35.1, lng: -82.1 })
  const [center, setCenter] = useState({ lat: 35.1, lng: -82.1})
  const [routes, setRoutes] = useState(null)
  const [routeOptionsList, setRouteOptionsList] = useState(null)
  const [whoOptionsList, setWhoOptionsList] = useState(null)
  const [routePlan, setRoutePlan] = useState({})
  const [routeId, setRouteId] = useState(null);
  const [numRouteOptions, setNumRouteOptions] = useState(0)
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [directionsRenderers, setDirectionsRenderers] = useState([])
  const [assetMap, setAssetMap] = useState(new Map());
  const [assetId, setAssetId] = useState(defaultGuy);
  const [lastAssetTimestamp, setLastAssetTimestamp] = useState(1672531200000);  // default to 1/1/2023 00:00:00
  const [searchResult, setSearchResult] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedPlaceMarker, setSelectedPlaceMarker] = useState(null);
  const [isLocalMobile, setIsLocalMobile] = useState(isMobile);
//  const [isLocalMobile, setIsLocalMobile] = useState(true);
  const [detailsBackground, setDetailsBackground] = useState('#80ff8080');
  const [windowWidth, setWindowWidth] = useState('100%');
  const [windowHeight, setWindowHeight] = useState('100%');

//  const searchRef = useRef()
  const whoRef = useRef()
  const selectedRouteRef = useRef();

  const logoMap = {
    'Chris Janko': 'https://findjanko.locatorx.com/LXMarkerCJ.png',
    'Jeff Kase': 'https://findjanko.locatorx.com/LXMarkerJK.png',
    'Tom Pringle': 'https://findjanko.locatorx.com/LXMarkerTP.png',
    'other': 'https://findjanko.locatorx.com/LXMarker.png',
  }
  const logoWarningMap = {
    'Chris Janko': 'https://findjanko.locatorx.com/LXMarkerCJ.warning.png',
    'Jeff Kase': 'https://findjanko.locatorx.com/LXMarkerJK.warning.png',
    'Tom Pringle': 'https://findjanko.locatorx.com/LXMarkerTP.warning.png',
    'other': 'https://findjanko.locatorx.com/LXMarker.warning.png',
  }
  const logoErrorMap = {
    'Chris Janko': 'https://findjanko.locatorx.com/LXMarkerCJ.error.png',
    'Jeff Kase': 'https://findjanko.locatorx.com/LXMarkerJK.error.png',
    'Tom Pringle': 'https://findjanko.locatorx.com/LXMarkerTP.error.png',
    'other': 'https://findjanko.locatorx.com/LXMarker.error.png',
  }

  const handleWindowSizeChange = () => {
    const wString = ''+window.innerWidth+'px';
    const hString = ''+window.innerHeight+'px';
    setWindowWidth(wString);
    setWindowHeight(hString);
    window.location.reload(false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const fetchData = useCallback(async () => {
    if (!routes) {
      setRoutes([])
      console.log('fetching the routes list...');
      const results = await getRoutes(coreProps);
      const rtesOptions = [];

      const routes = [];
      if (results.routes && results.routes.length > 0) {
        results.routes.sort(function(a,b) {
          if (a.name > b.name)
            return 1;
          if (a.name < b.name)
            return -1;

          return 0;
        })
        results.routes.forEach((r) => {
          const rte = {name: r.name,
              routeId: r.routeId};
          routes.push(rte);
          rtesOptions.push((<option value={r.routeId}>{r.name}</option>))
        })
        setRoutes(routes)
        setRouteOptionsList(rtesOptions);
      }  
    }

    const theTime = Date.now();
    console.log('fetchData at '+theTime+" "+lastAssetTimestamp+" "+currentLocation.lat+","+currentLocation.lng+" window: "+window.innerWidth+","+window.innerHeight);
    if (!routeId)
      return;

    const results = await getAssetsForRoute({...coreProps, routeId: routeId, lastAssetTimestamp: lastAssetTimestamp});

    if (results.assets && results.assets.length > 0) {
      console.log('**** have an asset update: '+results.assets[0].lastName+" "+results.assets[0].latitude+" "+results.assets[0].longitude);
      results.assets.forEach((a) => {
        let localName  = 'other';
        let title = '';
        let iconMapSelect = logoMap;
        let detBgnd = '#80ff8080';
        if (a.propertiesMap) {
          if (a.propertiesMap.name)
            localName = a.propertiesMap.name;

          if (a.propertiesMap.title)
            title = a.propertiesMap.title

          if (a.propertiesMap.distanceFromRoute > 1500) {
            detBgnd = '#ff808080';
//            iconMapSelect = logoErrorMap;
          } else if (a.propertiesMap.distanceFromRoute > 500) {
            detBgnd = '#ffff8080';
//            iconMapSelect = logoWarningMap;
          }
        }
//        let iconFile = ((localName in iconMapSelect) ? iconMapSelect[localName] : iconMapSelect['other']);
        let iconFile = 'https://findjanko.locatorx.com/chris.png';
        let marker;
        
        if (a.assetId === defaultGuy) {
          let thisIsOurGuy = true;
          setAssetId(defaultGuy);
          if (!assetMap.has(a.assetId)) {
            // new asset
            let wHoptions = [];
            if (whoOptionsList) {
              wHoptions = whoOptionsList;
              //         } else {
              //            thisIsOurGuy = true;
              //            whoRef.current.innerText = name;
              //            setAssetId(a.assetId);
            }

            wHoptions.push((<option value={a.assetId}>{(localName !== 'other' ? localName : a.assetId)}</option>))
            setWhoOptionsList(wHoptions);

            // eslint-disable-next-line no-undef
            marker = new google.maps.Marker({
              position: { lat: a.latitude, lng: a.longitude },  // state variable
              map,
              icon: iconFile,
            });
          } else {
            const aProperty = assetMap.get(a.assetId)
            marker = aProperty.marker
            marker.setMap(null)
            marker.position = { lat: a.latitude, lng: a.longitude };
            marker.icon = iconFile;
            marker.setMap(map)
          }
          if (a.assetId === assetId)
            thisIsOurGuy = true;

          const aMap = { name: localName, title: title, position: { lat: a.latitude, lng: a.longitude }, marker: marker };
          if (a.propertiesMap) {
            aMap.heading = a.propertiesMap.heading;
            aMap.speed = a.propertiesMap.speed;
            aMap.altitude = a.propertiesMap.altitude;
            aMap.distanceFromRoute = a.propertiesMap.distanceFromRoute;

            if (thisIsOurGuy) {
//              setName(localName)
              showOurGuy(aMap)
            }
          }
          assetMap.set(a.assetId, aMap)
          setAssetMap(assetMap);
          setCurrentLocation({ lat: a.latitude, lng: a.longitude });
          setLastAssetTimestamp(theTime);
          setDetailsBackground(detBgnd);
        }
      })
    }

//    setCurrentLocation({lat: (currentLocation.lat+0.01), lng: (currentLocation.lng+0.01)});
  }, [lastAssetTimestamp, currentLocation, routeId, routes])
  
  // the useEffect is only there to call `fetchData` at the right time
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
      // make sure to catch any error
      .catch(console.error);

    }, 5000);
      
      return () => clearInterval(interval);

  }, [fetchData])

  if (map && defaultRoute && !routeId) {
    setRouteId(defaultRoute)
    loadRoute(defaultRoute)
    if (!isLocalMobile) {
      selectedRouteRef.current.innerText = 'Biltmore Forest 2023-09-12';
    }
  }

  if (!isLoaded) {
    return <SkeletonText />
  }
  
  function setGoogleMap(googleMap)
  {
    setMap(googleMap);
  }

  async function loadRoute(routeId) 
  {
    const response = await getRoute({...coreProps, routeId: routeId})
    console.log(response);
    if (response.route) {
      const rtePlan = response.route.routePlan;
//      setRoute(response.route);
      setRoutePlan(rtePlan);
      setNumRouteOptions(rtePlan.routeOptions.length);

      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      const originString = rtePlan.origin.latitude + ','+rtePlan.origin.longitude;
      const destinationString = rtePlan.destination.latitude + ','+rtePlan.destination.longitude;
      const results = await directionsService.route({
        origin: originString,
        destination: destinationString,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      })
      setDirectionsResponse(results)
      setCurrentLocation({lat: rtePlan.origin.latitude, lng: rtePlan.origin.longitude})

      clearRoute();

      const primaryRoute = rtePlan.routeOptions[0];
      setDistance(Number(primaryRoute.distanceInMeters * kMetersToMiles).toFixed(2) + ' miles')
      setDuration(Number(primaryRoute.durationInSeconds / 60.0).toFixed(2) + ' mins');

      for (let i = rtePlan.routeOptions.length - 1; i >= 0 ; i--) {
        let strokeColor = '#ff0000';
        let opacity = 0.8;
        if (i > 0)
          strokeColor = '#808080';

        // eslint-disable-next-line no-undef
        const directionRenderer = new google.maps.DirectionsRenderer();
        directionRenderer.setMap(map);
        directionRenderer.setDirections(results);
        directionRenderer.setRouteIndex(i);
        directionRenderer.setOptions({ polylineOptions: { strokeColor: strokeColor, strokeOpacity: opacity } });

        directionsRenderers.push(directionRenderer);
      }
      setDirectionsRenderers(directionsRenderers);
    }
  };

  function clearRoute()
  {
      // disconnect and empty the array
      directionsRenderers.forEach((dr) => {
        dr.setMap(null)
      })
      directionsRenderers.length = 0;
      
      assetMap.forEach((a) => {
        if (a.marker) {
          a.marker.setMap(null);
        }
      })
      assetMap.clear();
      setAssetMap(assetMap);

      setDuration('');
      setDistance('');
      setOffRoute('');
      setHeading('');
      setSpeed('');
      setAltitude('');
      setAssetId('');
      setTitle('');
      setWhoOptionsList(null);
      setLastAssetTimestamp(1672531200000);
      setSelectedPlace(null)
      if (selectedPlaceMarker) {
        selectedPlaceMarker.setMap(null)
      }
      setSelectedPlaceMarker(null)
      if (!isLocalMobile)
        whoRef.current.innerText = 'Track This';
  }

  function selectRoute(event)
  {
    const { target } = event;
    if (target.type === 'select-one'){
      const selectValue = target.selectedOptions[0].value;
      console.log("selected "+selectValue);
      if (selectValue === 'Clear') {
        clearRoute();
      } else  {
        setRouteId(selectValue)
        loadRoute(selectValue)
      }
    }
  }

  function centerOnLocation()
  {
    if (selectedPlace) {
      map.setCenter(selectedPlace.geometry.location)
      if (selectedPlaceMarker) {
        selectedPlaceMarker.setMap(null)
        selectedPlaceMarker.position = selectedPlace.geometry.location;
        selectedPlaceMarker.icon = selectedPlace.icon;
        selectedPlaceMarker.setMap(map)
        setSelectedPlaceMarker(selectedPlaceMarker)
    } else {
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: selectedPlace.geometry.location, 
          map,
          icon: selectedPlace.icon,
        });  
        setSelectedPlaceMarker(marker)
      }
    }
  }

  function showOurGuy(aMap)
  {
    setTitle(aMap.name + ',' + aMap.title);
    setOffRoute(Number(aMap.distanceFromRoute).toFixed(2) + 'm');
    setHeading(aMap.heading + ' deg');
    setAltitude(aMap.altitude + ' feet');
    setSpeed(aMap.speed + ' mph');
  }

  function selectWho(event)
  {
    const { target } = event;
    if (target.type === 'select-one'){
      const selectValue = target.selectedOptions[0].value;
      console.log("selected "+selectValue);
      setAssetId(selectValue)
      const aMap = assetMap.get(selectValue)
      if (aMap) {
        showOurGuy(aMap)
      }
    }
  }

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }
  
  function onPlaceChanged() {
    if (searchResult != null) {
      //variable to store the result
      const place = searchResult.getPlace();
      //variable to store the name from place details result 
      const name = place.name;
      //variable to store the status from place details result
      const status = place.business_status;
      //variable to store the formatted address from place details result
      const formattedAddress = place.formatted_address;
      // console.log(place);
      //console log all results
      console.log(`Name: ${name}`);
      console.log(`Business Status: ${status}`);
      console.log(`Formatted Address: ${formattedAddress}`);
      /*  this is beta functionality
      const openStatus = await place.isOpen();
      place.open = (openStatus ? 'Open' : 'Closed')
      */
      setSelectedPlace(place)
    } else {
      alert("Please enter text");
    }
  }
  if (!isLocalMobile)
  return (
    <Flex position='relative' flexDirection='column' alignItems='center' h='100vh' w='100vw' >
      <Box position='absolute' left={0} top={0} h={windowHeight} w={windowWidth}>
        <MultiRouteMap {...coreProps} center={center} currentLocation={currentLocation} isMobile={isLocalMobile} onLoad={setGoogleMap} directionsResponse={directionsResponse} numRouteOptions={numRouteOptions} />
      </Box>

        <Box p={4} borderRadius='lg' m={4} bgColor='white' shadow='base' minW='container.md' zIndex='1' >
          <HStack spacing={2} justifyContent='space-between' >
            <VStack >
              <Box flexGrow={1} >
                <HStack>
                  <Select placeholder='Track This' w='300px' onChange={selectWho} ref={whoRef}>
                    {whoOptionsList ? whoOptionsList : null}
                  </Select>
                  {/* <IconButton aria-label='center back' icon={<FaSearch />} isRound onClick={searchAddress} /> */}
                </HStack>
              </Box>
              {assetId ?
                <VStack >
                  <Box>
                    <Text pt={2} fontSize='sm'>{title} </Text>
                  </Box>
                  <HStack mt={-2} mb={2}>
                    <Box >
                      <Text pt={2} fontSize='sm'>Hdg: {heading} </Text>
                      <Text pt={2} fontSize='sm'>Alt: {altitude} </Text>
                    </Box>
                    <Box ml={6}>
                      <Text pt={2} fontSize='sm'>Speed: {speed} </Text>
                      <Text pt={2} fontSize='sm'>{currentLocation.lat.toFixed(3) + ', ' + currentLocation.lng.toFixed(3)} </Text>
                    </Box>
                  </HStack>
                </VStack> : <Box h='100px' />}
            </VStack>
            <VStack verticalAlign='top'>
              <Box flexGrow={1} align='left'>
                <HStack >
                  <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                    <Input type='text' placeholder='Where to?' w='350px' />
                  </Autocomplete>
                  <IconButton aria-label='center back' icon={<FaLocationArrow />} isRound onClick={centerOnLocation} />
                </HStack>
              </Box>
              <HStack>
                {selectedPlace ?
                  <Box w='100%' h='100px'>
                    <Text pt={2} fontSize='sm'>{selectedPlace.formatted_address} </Text>
                    <Text pt={2} fontSize='sm'>{selectedPlace.formatted_phone_number} </Text>
                    <Link pt={2} fontSize='sm' href={selectedPlace.website} isExternal>{selectedPlace.website}</Link>
                  </Box>
                  : <Box h='100px' />
                }
              </HStack>
            </VStack>
            <VStack verticalAlign='top'>
              <Box flexGrow={1}>
                <Select placeholder='Select Route' w='300px' onChange={selectRoute} ref={selectedRouteRef}>
                  {routeOptionsList ? routeOptionsList : null}
                </Select>
              </Box>
              <HStack>
                {routeId ?
                  <Box w='100%' h='100px'>
                    <Text pt={2} fontSize='sm'>Distance: {distance} </Text>
                    <Text pt={2} fontSize='sm'>Duration: {duration} </Text>
                    <Text pt={2} fontSize='sm'>Off Route: {offRoute} </Text>
                  </Box>
                  : <Box h='100px' />}
              </HStack>
            </VStack>
          </HStack>

        </Box>
    </Flex>
  )
  return (
    <Flex position='relative' flexDirection='column' alignItems='center' h='100vh' w='100vw' >
      <Box position='absolute' left={0} top={0} w={windowWidth} h={windowHeight}>
        <MultiRouteMap {...coreProps} center={center} currentLocation={currentLocation} isMobile={isLocalMobile} onLoad={setGoogleMap} directionsResponse={directionsResponse} numRouteOptions={numRouteOptions} />
      </Box>

      <Box position='absolute' left={2} bottom={10} p={2} borderRadius='lg' m={2} bgColor={detailsBackground} shadow='base' minW='container.xsm' zIndex='1' >
        <VStack >
          <Text pt={-2} fontSize='12px' fontWeight={'bold'}>{title} </Text>
          <Text pt={-2} fontSize='12px'>Heading: {heading} </Text>
          <Text pt={-2} mt={-2} fontSize='12px'>Altitude: {altitude} </Text>
          <Text pt={-2} mt={-2} fontSize='12px'>Speed: {speed} </Text>
          {/*          <Divider orientation='horizontal' colorScheme='black' size={4}/> */}
          <Text pt={-2} mt={-2} fontSize='12px'>Distance: {distance} </Text>
          <Text pt={-2} mt={-2} fontSize='12px'>Duration: {duration} </Text>
          <Text pt={-2} mt={-2} fontSize='12px'>Off Route: {offRoute} </Text>
        </VStack>
      </Box>
    </Flex>
  )
}

export default App
