export const getRoute = async (props) => {
  const { apiUrl, authToken, organizationId, routeId } = props
  const bodyMap = { organizationId: organizationId }
  const results = await fetch(`${apiUrl}salesforce/routes/get/${routeId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": authToken,
    },
    body: JSON.stringify(bodyMap)
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err)
    })
  return results
}

export const getRoutes = async (props) => {
  const { apiUrl, authToken, organizationId } = props
  const bodyMap = { organizationId: organizationId }
  const results = await fetch(`${apiUrl}salesforce/routes/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": authToken,
    },
    body: JSON.stringify(bodyMap)
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err)
    })
  return results
}
