import {
    GoogleMap,
} from '@react-google-maps/api'
  
export function MultiRouteMap(props) {
    const { onLoad, center, isMobile } = props;

    return (
        <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            options={{
                zoomControl: !isMobile,
                streetViewControl: !isMobile,
                mapTypeControl: true,
                mapTypeControlOptions: {
            // eslint-disable-next-line no-undef
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                },
                fullscreenControl: isMobile,
                fullscreenControlOptions: {
            // eslint-disable-next-line no-undef
            position: google.maps.ControlPosition.LEFT_TOP
                }
            }}
            onLoad={map => onLoad(map)}
        >
        </GoogleMap>
    );
}
